import React from 'react'
import { graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import Layout from '~/components/Layout'
import PostPreview from '~/components/PostPreview'
import Pagination from '~/components/Pagination'
import { Post } from '~/classes/Post'
import { useApp } from '~/providers/AppProvider'

export interface PostListTemplateContext {
  urlPath: string
  limit: number
  skip: number
  numPages: number
  currentPage: number
}

interface PostListTemplateProps extends PageProps {
  data: Queries.PostListTemplateQuery
  pageContext: PostListTemplateContext
}

const PostListTemplate: React.FC<PostListTemplateProps> = ({ data, uri, pageContext }) => {
  const app = useApp()
  if (!app) return
  const posts = data.allMarkdownRemark.nodes.map(node => new Post(node))
  return (
    <Layout>
      {posts.map((post, index) => {
        return <PostPreview key={index} post={post} />
      })}
      <Pagination
        className="fixed-width mb-2"
        currentPage={pageContext.currentPage}
        numberOfPages={pageContext.numPages}
        getPageUriByIndex={n => (n === 1 ? `/` : `/page-${n}`)}
      ></Pagination>
    </Layout>
  )
}
export default PostListTemplate

export const query = graphql`
  query PostListTemplate($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { fields: { urlPath: { ne: null }, source: { eq: "posts" } } }
    ) {
      nodes {
        ...PostDataFragment
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
