import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { headerFontFamily, primaryColor, headerColor, activeColor } from '~/styling'
import { useApp } from '~/providers/AppProvider'
import useObservable from '~/utils/useObservable'

interface IPaginationProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  next?: string
  prev?: string
  numberOfPages: number
  currentPage: number
  getPageUriByIndex: (n: number) => string
}

const PageLink = styled(Link)`
  font-family: var(--bodyFamily);
  border: var(--primaryColor);
  font-size: 1.2em;
  border-radius: 0.14em;
  color: var(--primaryColor);
  padding: 0.4em 0.8em;
  margin-right: 0.4em;
  text-decoration: none;
  &:link {
    color: var(--primaryColor);
  }
  &:visited {
    color: var(--primaryColor);
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Current = styled.span`
  font-family: var(--bodyFamily);
  background: ${activeColor};
  font-size: 1.2em;
  border-radius: 0.14em;
  color: black;
  padding: 0.4em 0.8em;
  margin-right: 0.4em;
`

const Ellipsis = styled.span`
  font-family: var(--bodyFamily);
  font-size: 1.2em;
  color: var(--textColor);
  padding: 0.4em 0.2em;
  margin-right: 0.4em;
`

const Pagination: React.FC<IPaginationProps> = ({
  numberOfPages,
  currentPage,
  getPageUriByIndex,
  children,
  ...props
}) => {
  if (numberOfPages <= 0) return null

  const app = useApp()

  if (!app) return null

  const [pageWidth] = useObservable(app.pageWidth$)

  const nearPageCount = pageWidth > 420 ? 1 : 0

  const pages = new Set([1, currentPage, numberOfPages])
  for (let i = currentPage - nearPageCount; i <= currentPage + nearPageCount; i++) {
    if (i > 1 && i < numberOfPages) pages.add(i)
  }

  const pagesTitles: Array<string | number> = []

  let prevNum = 0
  for (const num of Array.from(pages).sort((a, b) => a - b)) {
    if (num === prevNum + 1) {
      pagesTitles.push(num)
    } else {
      pagesTitles.push('...')
      pagesTitles.push(num)
    }
    prevNum = num
  }

  const pageElems = pagesTitles.map((page, index) =>
    page === currentPage ? (
      <Current key={index}>{page}</Current>
    ) : page === '...' ? (
      <Ellipsis key={index}>...</Ellipsis>
    ) : (
      <PageLink key={index} to={getPageUriByIndex(page as number)}>
        {page}
      </PageLink>
    )
  )

  return (
    <div {...props}>
      {currentPage > 1 && <PageLink to={getPageUriByIndex(currentPage - 1)}> ←</PageLink>}
      {pageWidth > 360 ? pageElems : <Current>{currentPage}</Current>}
      {numberOfPages > 1 && currentPage < numberOfPages && (
        <PageLink to={getPageUriByIndex(currentPage + 1)}>→ </PageLink>
      )}
    </div>
  )
}

export default Pagination
